import * as actionTypes from './types';
import axios from 'axios';

const API = axios.create({
    baseURL: "https://scdn.monster"
});


export const fetchConfig = () => dispatch => {
    API.get('/api/config').then(({data}) => {
        dispatch({
            type: actionTypes.FETCH_CONFIG_SUCCESS,
            payload: data
        })
    }).catch((e) => {
        dispatch({
            type: actionTypes.FETCH_CONFIG_ERROR,
            payload: e
        })
    })
};

export const fetchGames = () => dispatch => {
    API.get('/api/sport/mlb').then(({data}) => {
        dispatch({
            type: actionTypes.FETCH_GAMES_SUCCESS,
            payload: data
        })
    }).catch((e) => {
        dispatch({
            type: actionTypes.FETCH_GAMES_ERROR,
            payload: e
        })
    })
};

export const fetchGame = (id) => (dispatch) => {
    API.get('/api/game/mlb/' + id).then(({data}) => {
        dispatch({
            type: actionTypes.FETCH_GAME_SUCCESS,
            payload: data
        });
    }).catch((e) => dispatch({
        type: actionTypes.FETCH_GAME_ERROR,
        payload: e
    }))
};

export const destroyGame = () => dispatch => {
    dispatch({
        type: actionTypes.DESTROY_GAME
    });
};
